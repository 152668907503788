@import url('https://fonts.googleapis.com/css?family=Fira+Mono&display=swap');

.cke_chrome {
    border: #f8f8f8 1px solid!important;
}
.cke_chrome .cke_top {
    background-color: white!important;
}

.cke_chrome .cke_bottom {
    background-color: white!important;
}

.cke_chrome a.cke_button{
    border-radius: 2px;
    margin: 1px;
}

.cke_chrome a.cke_button:hover {
    border-color: #eee;
    background-color: #eee!important;
}

.cke_chrome a.cke_button_on {
    border-color: #dddddd!important;
}

textarea.cke_source{
    font-family: 'Fira Mono'!important;
    font-size: 12px!important;
}