html, body, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.rich-content h1, .rich-content h2, .rich-content h3, .rich-content h4{
  font-weight: 500;
}
.rich-content b, .rich-content strong{
  font-weight: 500;
}